<template>
  <div
    class="companyTerminalAddResult"
    v-watermark
  >
    <commonNavBar :title="'终端设备地址查询'"></commonNavBar>
    <!-- 加载中 -->
    <van-overlay
      :show="loadingShow"
      class-name="myLoading"
    >
      <van-loading
        text-color="#0094ff"
        color="#0094ff"
        vertical
      >加载中...</van-loading>
    </van-overlay>
    <div class="searchBox">
      <form action="/">
        <van-search
          :value="$route.query.text"
          show-action
          :clearable="false"
          readonly
          @click="$router.back()"
        >
          <template #action>
            <div @click="$router.back()">取消</div>
          </template>
        </van-search>
      </form>
      <div class="siftDp">
        <van-dropdown-menu
          active-color="#3ea7f0"
          :close-on-click-outside="flag"
        >
          <van-dropdown-item
            ref="statusItem"
            :title="statusTitle"
            :disabled="loadingShow"
            @open="statusSelect = confirmStatusSelect"
          >
            <van-checkbox-group v-model="statusSelect">
              <van-cell-group>
                <van-cell
                  v-for="(item, index) in status"
                  clickable
                  :key="item.id"
                  :title="item.name"
                  @click="toggle(index)"
                >
                  <template #right-icon>
                    <van-checkbox
                      :name="item.id"
                      ref="checkboxes"
                    />
                  </template>
                </van-cell>
              </van-cell-group>
              <div class="myButton">
                <van-button
                  @click="statusSelect = []"
                  color="#969799"
                  block
                  size="small"
                >
                  重置
                </van-button>
                <van-button
                  type="info"
                  block
                  size="small"
                  @click="statusItemClosed"
                >
                  确认
                </van-button>
              </div>
            </van-checkbox-group>
          </van-dropdown-item>
          <van-dropdown-item
            :title="timeTitle"
            ref="timeItem"
            @open="openTimeOpened"
            :disabled="loadingShow"
          >
            <van-cell
              title="选择开通日期区间"
              :value="selectTime"
              @click="showTimeSelect"
              is-link
              center
              title-style="flex:none"
            />
            <van-field name="radio">
              <template #input>
                <van-radio-group
                  v-model="radio"
                  direction="horizontal"
                  @change="openTimeChange"
                >
                  <van-radio
                    :name="item.value"
                    v-for="(item, index) in radioArr"
                    :key="index"
                  >
                    <template #default>
                      <van-tag
                        :color="radio === item.value ? '#3e89fa' : '#969799'"
                        size="medium"
                      >{{
                        item.text
                      }}</van-tag>
                    </template>
                    <template #icon>
                      <div></div>
                    </template>
                  </van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <div class="myButton">
              <van-button
                @click="(selectTime = ''), (radio = '')"
                color="#969799"
                block
                size="small"
              >
                重置
              </van-button>
              <van-button
                type="info"
                block
                size="small"
                @click="confirmOpenTimeClick"
              >
                确认
              </van-button>
            </div>
          </van-dropdown-item>
          <van-dropdown-item
            :title="moreTitle"
            ref="moreItem"
            :disabled="loadingShow"
            @open="openMoreOpened"
          >
            <div class="more">
              <div class="moreItem">
                <div class="moreLabel">通讯方式</div>
                <div class="itemValue">
                  <van-field name="communicationModeSelect">
                    <template #input>
                      <van-checkbox-group
                        v-model="communicationModeSelect"
                        direction="horizontal"
                      >
                        <van-checkbox
                          :name="item.id"
                          shape="square"
                          v-for="(item, index) in communicationMode"
                          :key="index"
                        >
                          <template #default>
                            <van-tag
                              :color="communicationModeSelect.includes(item.id)
                                ? '#3e89fa'
                                : '#969799'
                                "
                              size="medium"
                            >{{ item.name }}</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-checkbox>
                      </van-checkbox-group>
                    </template>
                  </van-field>
                </div>
              </div>
              <div class="moreItem">
                <div class="moreLabel">共用终端</div>
                <div class="itemValue">
                  <van-field name="shared">
                    <template #input>
                      <van-radio-group
                        v-model="shared"
                        direction="horizontal"
                      >
                        <van-radio :name="0">
                          <template #default>
                            <van-tag
                              :color="shared === 0 ? '#3e89fa' : '#969799'"
                              size="medium"
                            >是</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-radio>
                        <van-radio :name="1">
                          <template #default>
                            <van-tag
                              :color="shared === 1 ? '#3e89fa' : '#969799'"
                              size="medium"
                            >否</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-radio>
                      </van-radio-group>
                    </template>
                  </van-field>
                </div>
              </div>
              <div class="moreItem">
                <div class="moreLabel">通讯类型</div>
                <div class="itemValue">
                  <van-field name="communicationTypeSelect">
                    <template #input>
                      <van-checkbox-group
                        v-model="communicationTypeSelect"
                        direction="horizontal"
                      >
                        <van-checkbox
                          :name="item.id"
                          shape="square"
                          v-for="(item, index) in communicationType"
                          :key="index"
                        >
                          <template #default>
                            <van-tag
                              :color="communicationTypeSelect.includes(item.id)
                                ? '#3e89fa'
                                : '#969799'
                                "
                              size="medium"
                            >{{ item.name }}</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-checkbox>
                      </van-checkbox-group>
                    </template>
                  </van-field>
                </div>
              </div>
              <div class="moreItem">
                <div class="moreLabel">运营商</div>
                <div class="itemValue">
                  <van-field name="operatorSelect">
                    <template #input>
                      <van-checkbox-group
                        v-model="operatorSelect"
                        direction="horizontal"
                      >
                        <van-checkbox
                          :name="item.id"
                          shape="square"
                          v-for="(item, index) in operator"
                          :key="index"
                        >
                          <template #default>
                            <van-tag
                              :color="operatorSelect.includes(item.id)
                                ? '#3e89fa'
                                : '#969799'
                                "
                              size="medium"
                            >{{ item.name }}</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-checkbox>
                      </van-checkbox-group>
                    </template>
                  </van-field>
                </div>
              </div>
              <div class="moreItem">
                <div class="moreLabel">主机标识</div>
                <div class="itemValue">
                  <van-field name="identification">
                    <template #input>
                      <van-radio-group
                        v-model="identification"
                        direction="horizontal"
                      >
                        <van-radio :name="0">
                          <template #default>
                            <van-tag
                              :color="identification === 0 ? '#3e89fa' : '#969799'
                                "
                              size="medium"
                            >是</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-radio>
                        <van-radio :name="1">
                          <template #default>
                            <van-tag
                              :color="identification === 1 ? '#3e89fa' : '#969799'
                                "
                              size="medium"
                            >否</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-radio>
                      </van-radio-group>
                    </template>
                  </van-field>
                </div>
              </div>
              <div class="moreItem">
                <div class="moreLabel">接入类型</div>
                <div class="itemValue">
                  <van-field name="accessTypeSelect">
                    <template #input>
                      <van-checkbox-group
                        v-model="accessTypeSelect"
                        direction="horizontal"
                      >
                        <van-checkbox
                          :name="item.id"
                          shape="square"
                          v-for="(item, index) in accessType"
                          :key="index"
                        >
                          <template #default>
                            <van-tag
                              :color="accessTypeSelect.includes(item.id)
                                ? '#3e89fa'
                                : '#969799'
                                "
                              size="medium"
                            >{{ item.name }}</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-checkbox>
                      </van-checkbox-group>
                    </template>
                  </van-field>
                </div>
              </div>
              <div class="moreItem">
                <div class="moreLabel">配套方式</div>
                <div class="itemValue">
                  <van-field name="matchingModeSelect">
                    <template #input>
                      <van-checkbox-group
                        v-model="matchingModeSelect"
                        direction="horizontal"
                      >
                        <van-checkbox
                          :name="item.id"
                          shape="square"
                          v-for="(item, index) in matchingMode"
                          :key="index"
                        >
                          <template #default>
                            <van-tag
                              :color="matchingModeSelect.includes(item.id)
                                ? '#3e89fa'
                                : '#969799'
                                "
                              size="medium"
                            >{{ item.name }}</van-tag>
                          </template>
                          <template #icon>
                            <div></div>
                          </template>
                        </van-checkbox>
                      </van-checkbox-group>
                    </template>
                  </van-field>
                </div>
              </div>
            </div>
            <div class="myButton">
              <van-button
                color="#969799"
                block
                size="small"
                @click="
                  communicationModeSelect = [];
                communicationTypeSelect = [];
                operatorSelect = [];
                accessTypeSelect = [];
                matchingModeSelect = [];
                shared = '';
                identification = '';
                "
              >
                重置
              </van-button>
              <van-button
                type="info"
                block
                size="small"
                @click="confirmMoreClick"
              >
                确认
              </van-button>
            </div>
          </van-dropdown-item>
          <van-dropdown-item
            v-model="sequence"
            :options="sequenceOption"
            :title="sequenceTitle"
            :disabled="loadingShow"
            @change="sequenceChange"
          />
        </van-dropdown-menu>
      </div>
      <div
        class="searchBar"
        v-if="companyList.length"
      >
        <span class="label">搜索结果:共{{ total }}条</span>
      </div>
      <!-- 结果列表 -->
      <div
        class="listBox"
        v-if="companyList.length"
        @scroll="onScroll"
        ref="listBox"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :error.sync="error"
          error-text="请求失败,点击重新加载!"
        >
          <!-- 卡片 -->
          <div
            class="myCard"
            v-for="(item, index) in companyList"
            :key="index"
            @click="
              $router.push(
                `/workTable/companyTerminalAddressBookInfo/${item.id}`
              )
              "
          >
            <div class="name">
              <span class="label">{{ item.code || "--" }}</span>
              <van-tag
                type="success"
                v-if="item.status === 0"
              >在用</van-tag>
              <van-tag
                type="primary"
                v-else-if="item.status === 1"
              >拆回</van-tag>
              <van-tag
                type="warning"
                v-else-if="item.status === 2"
              >调试</van-tag>
              <van-tag
                type="danger"
                v-else-if="item.status === 3"
              >停卡</van-tag>
              <van-tag
                color="#aaaaaa"
                v-else-if="item.status === 4"
              >遗失</van-tag>
            </div>
            <p>产品编号:{{ item.productCode || "--" }}</p>
            <p>单位名称:{{ item.companyName || "--" }}</p>
            <p>开通日期:{{ item.openTime | openYmdFormat }}</p>
            <p>安装地址:{{ item.address || "--" }}</p>
          </div>
        </van-list>
      </div>

      <!-- 空结果 -->
      <div
        class="empty"
        v-else
      >
        <van-empty
          image="search"
          description="没有符合条件的数据"
        />
      </div>
    </div>
    <!-- 开通日期区间选择 -->
    <van-calendar
      title="开通日期区间选择"
      v-model="timeSelectShow"
      type="range"
      ref="timeSelectPicker"
      allow-same-day
      @confirm="onConfirmTime"
      :min-date="minDate"
      :max-date="maxDate"
      color="#3ea7f0"
      @closed="flag = true"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { getCompanyTerminalList } from '@/api/companyTerminal'
import { getDictionaryByTypeList } from '@/api/system'

export default {
  name: 'companyTerminalAddResult',
  data() {
    return {

      option1: [
        { text: '单位名', value: 0 },
        { text: '编号', value: 1 }
      ],
      companyList: [],
      loading: false,
      finished: false,
      top: 0,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      error: false,
      loadingShow: false,
      status: [
        { id: 0, name: '在用' },
        { id: 1, name: '拆回' },
        { id: 2, name: '调试' },
        { id: 3, name: '停卡' },
        { id: 4, name: '遗失' }
      ],
      statusSelect: [],
      confirmStatusSelect: [],
      statusTitle: '状态',
      selectTime: '',
      confirmSelectTime: '',
      timeTitle: '开通日期',
      timeSelectShow: false,
      flag: true,
      radio: '',
      confirmRadio: '',
      radioArr: [
        {
          value: '3-day',
          text: '近三天'
        },
        {
          value: '1-week',
          text: '近一周'
        },
        {
          value: '1-month',
          text: '近一个月'
        },
        {
          value: '3-month',
          text: '近三个月'
        },
        {
          value: '6-month',
          text: '近半年'
        },
        {
          value: '1-year',
          text: '近一年'
        }
      ],
      sequenceOption: [
        { text: '默认排序', value: 'default' },
        { text: '开通日期由近及远', value: 'desc' },
        { text: '开通日期由远及近', value: 'asc' }
      ],
      sequence: '',
      sequenceTitle: '默认排序',
      moreTitle: '更多',
      communicationMode: [],
      communicationModeSelect: [],
      confirmcommunicationModeSelect: [],
      communicationType: [],
      communicationTypeSelect: [],
      confirmcommunicationTypeSelect: [],
      operator: [],
      operatorSelect: [],
      confirmoperatorSelect: [],
      accessType: [],
      accessTypeSelect: [],
      confirmaccessTypeSelect: [],
      matchingMode: [],
      matchingModeSelect: [],
      confirmmatchingModeSelect: [],
      shared: '',
      confirmShared: '',
      identification: '',
      confirmIdentification: ''
    }
  },
  // 激活页面
  activated() {
    if (this.$refs.listBox) {
      this.$refs.listBox.scrollTop = this.top
    }
  },
  // 路由进入页面
  beforeRouteEnter(to, from, next) {
    if (from.path.includes('/workTable/companyTerminalAddressBookInfo')) {
      next(vm => {
        if (!vm.companyList.length) {
          vm.getDictionaryByTypeList()
          vm.onLoad()
        }
      })
    } else {
      next(vm => {
        Object.assign(vm.$data, vm.$options.data.call(vm))
        vm.getDictionaryByTypeList()
        vm.onLoad()
      })
    }
  },
  computed: {
    minDate() {
      return new Date(1970, 0, 1)
    },
    maxDate() {
      return new Date()
    }
  },
  watch: {
    confirmStatusSelect(val) {
      if (!val.length) {
        this.statusTitle = '状态'
      } else if (val.length === 1) {
        this.statusTitle = this.status[
          this.status.findIndex(item => {
            return item.id === val[0]
          })
        ].name
      } else if (val.length > 1) {
        this.statusTitle = '状态(多选)'
      } else {
        this.statusTitle = '状态'
      }
    },
    sequence(val) {
      if (val === '') {
        this.sequenceTitle = '默认排序'
      } else if (val === 'default') {
        this.sequenceTitle = '默认排序'
      } else if (val === 'asc') {
        this.sequenceTitle = '开通日期由远及近'
      } else if (val === 'desc') {
        this.sequenceTitle = '开通日期由近及远'
      } else {
        this.sequenceTitle = '默认排序'
      }
    }
  },
  methods: {
    // 加载列表
    async onLoad() {
      if (this.pageNum === 1) {
        this.loadingShow = true
      }
      try {
        const { data } = await getCompanyTerminalList({
          type: 3,
          'page.current': this.pageNum,
          'page.size': this.pageSize,
          'sort.key': '0',
          'sort.value':
            this.sequence === '' || this.sequence === 'default'
              ? 'desc'
              : this.sequence,
          search: this.$route.query.text,
          fuzzy: 1,
          status: this.confirmStatusSelect.length
            ? this.confirmStatusSelect.toString()
            : null,
          openDatetimeStart: this.confirmSelectTime
            ? this.confirmSelectTime.split('-')[0]
            : null,
          openDatetimeEnd: this.confirmSelectTime
            ? this.confirmSelectTime.split('-')[1]
            : null,
          cycle: this.confirmRadio ? +this.confirmRadio.split('-')[0] : null,
          cycleType: this.confirmRadio ? this.confirmRadio.split('-')[1] : null,
          communicationMode: this.confirmcommunicationModeSelect.length
            ? this.confirmcommunicationModeSelect.toString()
            : null,
          communicationType: this.confirmcommunicationTypeSelect.length
            ? this.confirmcommunicationTypeSelect.toString()
            : null,
          operator: this.confirmoperatorSelect.length
            ? this.confirmoperatorSelect.toString()
            : null,
          accessType: this.confirmaccessTypeSelect.length
            ? this.confirmaccessTypeSelect.toString()
            : null,
          matchingMode: this.confirmmatchingModeSelect.length
            ? this.confirmmatchingModeSelect.toString()
            : null,
          shared:
            this.confirmShared || this.confirmShared === 0
              ? this.confirmShared
              : null,
          identification:
            this.confirmIdentification || this.confirmIdentification === 0
              ? this.confirmIdentification
              : null
        })
        this.total = data.data.total
        this.pageNum = this.pageNum + 1
        // 追加数据
        const results = data.data.records
        this.companyList.push(...results)
        this.loading = false
        if (!results.length) {
          this.finished = true
        }
        if (this.loadingShow) {
          this.loadingShow = false
        }
      } catch (error) {
        if (error.message === '取消请求') {
          this.error = false
          return
        }
        // 加载状态结束
        this.loading = false
        this.error = true
        if (this.loadingShow) {
          this.loadingShow = false
        }
        if (
          error?.response?.status === 401
        ) {
          return
        }
        if (error.message.includes('timeout')) {
          if (this.$route.path === '/workTable/companyTerminalAddResult') {
            this.$toast.fail({
              message: '网络不好，请稍后再试!',
              duration: 500
            })
          }
        }
      }
    },
    onScroll(e) {
      this.top = e.target.scrollTop
    },
    // 批量获取数据字典下拉列表
    async getDictionaryByTypeList() {
      const {
        data: { data }
      } = await getDictionaryByTypeList({
        types: [
          'equipment-terminal-communication-mode',
          'equipment-terminal-communication-type',
          'equipment-terminal-operator',
          'host-access-type',
          'host-matching-mode'
        ].toString()
      })
      this.communicationMode = data['equipment-terminal-communication-mode']
      this.communicationType = data['equipment-terminal-communication-type']
      this.operator = data['equipment-terminal-operator']
      this.accessType = data['host-access-type']
      this.matchingMode = data['host-matching-mode']
    },
    // 状态选择
    toggle(index) {
      this.$refs.checkboxes[index].toggle()
    },
    // 关闭状态
    statusItemClosed() {
      this.confirmStatusSelect = this.statusSelect
      this.pageNum = 1
      this.error = false
      this.top = 0
      this.companyList = []
      if (this.$refs.listBox) {
        this.$refs.listBox.scrollTop = 0
      }
      this.finished = false
      this.onLoad()
      this.$refs.statusItem.toggle(false)
    },
    // 打开时间选择
    showTimeSelect() {
      this.timeSelectShow = true
      this.flag = false
      if (this.selectTime) {
        this.$refs.timeSelectPicker.reset([
          new Date(this.selectTime.split('-')[0]),
          new Date(this.selectTime.split('-')[1])
        ])
      } else {
        this.$refs.timeSelectPicker.reset([new Date()])
      }
    },
    // 时间区间选择确认
    onConfirmTime(date) {
      const [start, end] = date
      this.timeSelectShow = false
      this.selectTime = `${dayjs(start).format('YYYY/MM/DD')}-${dayjs(
        end
      ).format('YYYY/MM/DD')}`
      this.radio = ''
    },
    // 开通时间标签改变
    openTimeChange(name) {
      if (name) {
        this.selectTime = ''
      }
    },
    // 打开开通日期下拉框
    openTimeOpened() {
      this.selectTime = this.confirmSelectTime
      this.radio = this.confirmRadio
    },
    // 开通日期确认
    confirmOpenTimeClick() {
      if (this.radio === '' && this.selectTime === '') {
        this.timeTitle = '开通日期'
      } else if (this.radio === '3-day' && this.selectTime === '') {
        this.timeTitle = '近三天'
      } else if (this.radio === '1-week' && this.selectTime === '') {
        this.timeTitle = '近一周'
      } else if (this.radio === '1-month' && this.selectTime === '') {
        this.timeTitle = '近一个月'
      } else if (this.radio === '3-month' && this.selectTime === '') {
        this.timeTitle = '近三个月'
      } else if (this.radio === '6-month' && this.selectTime === '') {
        this.timeTitle = '近半年'
      } else if (this.radio === '1-year' && this.selectTime === '') {
        this.timeTitle = '近一年'
      } else if (this.selectTime) {
        this.timeTitle = this.selectTime
      }
      this.confirmSelectTime = this.selectTime
      this.confirmRadio = this.radio
      this.pageNum = 1
      this.error = false
      this.top = 0
      this.companyList = []
      if (this.$refs.listBox) {
        this.$refs.listBox.scrollTop = 0
      }
      this.finished = false
      this.onLoad()
      this.$refs.timeItem.toggle(false)
    },
    // 排序变化
    sequenceChange() {
      this.pageNum = 1
      this.error = false
      this.top = 0
      this.companyList = []
      if (this.$refs.listBox) {
        this.$refs.listBox.scrollTop = 0
      }
      this.finished = false
      this.onLoad()
    },
    // 打开更多下拉框
    openMoreOpened() {
      this.communicationModeSelect = this.confirmcommunicationModeSelect
      this.communicationTypeSelect = this.confirmcommunicationTypeSelect
      this.operatorSelect = this.confirmoperatorSelect
      this.accessTypeSelect = this.confirmaccessTypeSelect
      this.matchingModeSelect = this.confirmmatchingModeSelect
      this.shared = this.confirmShared
      this.identification = this.confirmIdentification
    },
    // 更多确认
    confirmMoreClick() {
      this.confirmcommunicationModeSelect = this.communicationModeSelect
      this.confirmcommunicationTypeSelect = this.communicationTypeSelect
      this.confirmoperatorSelect = this.operatorSelect
      this.confirmaccessTypeSelect = this.accessTypeSelect
      this.confirmmatchingModeSelect = this.matchingModeSelect
      this.confirmShared = this.shared
      this.confirmIdentification = this.identification
      if (
        (
          !this.confirmcommunicationModeSelect.length &&
          !this.confirmcommunicationTypeSelect.length &&
          !this.confirmoperatorSelect.length &&
          !this.confirmaccessTypeSelect.length &&
          !this.confirmmatchingModeSelect.length &&
          this.confirmShared === '' &&
          this.confirmIdentification === '') ||

        (
          this.confirmcommunicationModeSelect.length === 1 &&
          !this.confirmcommunicationTypeSelect.length &&
          !this.confirmoperatorSelect.length &&
          !this.confirmaccessTypeSelect.length &&
          !this.confirmmatchingModeSelect.length &&
          this.confirmShared === '' &&
          this.confirmIdentification === '') ||
        (
          !this.confirmcommunicationModeSelect.length &&
          this.confirmcommunicationTypeSelect.length === 1 &&
          !this.confirmoperatorSelect.length &&
          !this.confirmaccessTypeSelect.length &&
          !this.confirmmatchingModeSelect.length &&
          this.confirmShared === '' &&
          this.confirmIdentification === '') ||
        (
          !this.confirmcommunicationModeSelect.length &&
          !this.confirmcommunicationTypeSelect.length &&
          this.confirmoperatorSelect.length === 1 &&
          !this.confirmaccessTypeSelect.length &&
          !this.confirmmatchingModeSelect.length &&
          this.confirmShared === '' &&
          this.confirmIdentification === '') ||
        (
          !this.confirmcommunicationModeSelect.length &&
          !this.confirmcommunicationTypeSelect.length &&
          !this.confirmoperatorSelect.length &&
          this.confirmaccessTypeSelect.length === 1 &&
          !this.confirmmatchingModeSelect.length &&
          this.confirmShared === '' &&
          this.confirmIdentification === '') ||
        (
          !this.confirmcommunicationModeSelect.length &&
          !this.confirmcommunicationTypeSelect.length &&
          !this.confirmoperatorSelect.length &&
          !this.confirmaccessTypeSelect.length &&
          this.confirmmatchingModeSelect.length === 1 &&
          this.confirmShared === '' &&
          this.confirmIdentification === '') ||
        (
          !this.confirmcommunicationModeSelect.length &&
          !this.confirmcommunicationTypeSelect.length &&
          !this.confirmoperatorSelect.length &&
          !this.confirmaccessTypeSelect.length &&
          this.confirmmatchingModeSelect.length === 1 &&
          this.confirmShared !== '' &&
          this.confirmIdentification === '') ||
        (
          !this.confirmcommunicationModeSelect.length &&
          !this.confirmcommunicationTypeSelect.length &&
          !this.confirmoperatorSelect.length &&
          !this.confirmaccessTypeSelect.length &&
          this.confirmmatchingModeSelect.length === 1 &&
          this.confirmShared === '' &&
          this.confirmIdentification !== '')
      ) {
        this.moreTitle = '更多'
      } else {
        this.moreTitle = '更多(多选)'
      }
      this.pageNum = 1
      this.error = false
      this.top = 0
      this.companyList = []
      if (this.$refs.listBox) {
        this.$refs.listBox.scrollTop = 0
      }
      this.finished = false
      this.onLoad()
      this.$refs.moreItem.toggle(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.companyTerminalAddResult {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #eeeeee;

  ::v-deep {
    .myLoading {
      top: 148px;
      bottom: 0;
      height: auto;

      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .van-calendar__popup.van-popup--bottom,
    .van-calendar__popup.van-popup--top {
      height: 61% !important;
    }

    .searchBox {
      height: calc(100% - 46px);
      overflow: auto;

      .van-search__label {
        .van-dropdown-menu {
          height: 100%;
          width: 100%;
          background-color: transparent;
          overflow: hidden;

          .van-dropdown-menu__bar {
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            background-color: transparent;
            box-shadow: none;

            .van-ellipsis {
              padding-right: 5px;
              font-size: 14px;
            }
          }

          .van-dropdown-menu__title::after {
            right: 1px;
          }
        }

        .van-dropdown-item {
          .van-dropdown-item__content {
            .van-dropdown-item__option {
              .van-cell__title {
                padding-left: 25px;
                font-size: 14px;
              }
            }
          }
        }

        .van-dropdown-menu__item--disabled {
          .van-dropdown-menu__title {
            color: #323233;
          }
        }
      }

      .siftDp {
        overflow: hidden;

        .van-dropdown-menu {
          >div {
            &:nth-child(4) {
              .van-dropdown-item__content {
                height: 100%;
                max-height: none;
                overflow: hidden;

                .more {
                  height: calc(100% - 52px);
                  overflow: auto;

                  .moreLabel {
                    font-size: 16px;
                    padding-left: 16px;
                  }

                  .itemValue {
                    .van-radio--horizontal {
                      margin-right: 12px;
                      width: auto;
                      margin-bottom: 0;
                    }
                  }
                }

                .myButton {
                  width: 100%;
                  padding-bottom: 15px;
                }
              }
            }
          }

          .van-dropdown-menu__bar {
            box-shadow: none;
          }
        }

        .van-radio--horizontal {
          margin-right: 0;
          width: 25%;
          margin-bottom: 10px;
        }

        .van-checkbox--horizontal {
          margin-right: 0;
          margin-bottom: 10px;
        }

        .myButton {
          padding: 5px 16px;
          display: flex;

          .van-button--small {
            margin: 0 5px;
          }

          .van-tree-select__item--active {
            color: #3ea7f0;
          }
        }
      }

      .searchBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        height: 24px;
        padding-bottom: 5px;
        background-color: #eeeeee;

        .label {
          font-size: 12px;
          color: #aaaaaa;
          padding-top: 5px;
        }

        .myIcon {
          width: 20px;
          height: 20px;
        }
      }
    }

    .empty {
      height: calc(100% - 54px - 24px - 48px);
      position: relative;

      .van-empty {
        position: absolute;
        width: 100%;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    .listBox {
      height: calc(100% - 24px - 54px - 48px);
      overflow: auto;
      padding: 0 7px;

      // padding-top: 10px;
      // padding: 9px 0;
      // padding-bottom: 0px;
      .myCard {
        // margin-bottom: 9px;
        // background-color: white;
        margin-bottom: 10px;
        background-color: white;
        border-radius: 5px;
        padding: 5px;

        .name {
          font-size: 0;
          margin-bottom: 5px;

          .label {
            font-size: 14px;
            margin-right: 10px;
            font-weight: 700;
          }
        }

        >p {
          font-size: 12px;
          color: #969799;
          margin-bottom: 5px;
        }
      }
    }
  }
}
</style>
